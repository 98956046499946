<template>
  <div v-if="!isLoading">
    <b-card class="p-lg-1 mx-5">
      <div>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon
            v-if="this.$route.query.msg == 'Payment_was_successful'"
            icon="CheckIcon"
            size="400"
            class="text-success"
          />
          <feather-icon
            v-else
            icon="XCircleIcon"
            size="400"
            class="text-warning"
          />
        </div>
        <div class="d-flex justify-content-center align-items-center my-4">
          <div v-if="this.$route.query.msg == 'Payment_was_successful'">
            <h1>Payment was successful. Thank you !</h1>
            <h3 class="d-flex justify-content-center align-items-center">
              Logpoint reloaded.
            </h3>
          </div>
          <h1 v-else>
            Your payment was declined. Please check with your bank. Thank you !
          </h1>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          v-if="this.$route.query.msg == 'Payment_was_successful'"
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          href="/log-points"
          >Continue Reloading Logpoint</b-button
        >
        <b-button
          v-else
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          href="/log-points"
          >Retry Reloading Logpoint</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      payment: "",
      paymentStatus: "",
      orderLabel: {},
    };
  },
  mounted() {
    if (this.$route.query.callback_status) {
      this.$http
        .get(`get_order/${this.$route.query.payment_id}`)
        .then((response) => {
          this.isLoading = false;
          if (response.status) {
            this.orderLabel = response.data.data;
          }
          this.checkPayment();
        });
    } else {
      this.$http
        .get(`get_order/${this.$route.params.data}`)
        .then((response) => {
          this.isLoading = false;
          if (response.status) {
            this.orderLabel = response.data.data;
          }
          this.checkPayment();
        });
    }
  },
  methods: {
    checkPayment() {
      this.$http
        .get(`check_order_payment_status/${this.orderLabel.id}`)
        .then((response) => {
          this.payment = response.data.data.status;
          this.paymentStatus = response.data.data.remarks;
        });
    },
    generateLabel() {
      this.$refs["generateLabel"].show();
    },

    downloadInvoice() {
      this.$refs["downloadInvoice"].show();
    },

    continueOrdering() {
      this.$router.replace("/outlet-order");
    },

    retryPay() {
      console.log("test");
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-width: 3px !important;
}
</style>